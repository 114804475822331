import { Carousel } from './init-carousel';
import { Navigation } from 'swiper/modules';

const carouselOptions = {
    modules: [Navigation],
    autoHeight: true,
    navigation: {
        nextEl: '.review-carousel .review-carousel__next',
        prevEl: '.review-carousel .review-carousel__prev',
    },
    slidesPerView: 1,
};

const carousel = document.querySelector('[data-carousel="reviews"]');

const reviewsCarousel = new Carousel(carousel, carouselOptions);

reviewsCarousel.initSwiper();
